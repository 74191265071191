import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import { useNavigationStore } from "../components/nav/state";
import { extractFeatureFlags } from "../features/users/featureFlags";
import { isError } from "../utils/graphql";
import { getConnectionNodesTyped } from "../utils/relay";
import {
  BasicFileFragmentFragment,
  BasicUserFragmentFragment,
  GetBasicData,
  GetBasicDataWorkspaceFragmentFragment,
} from "./queries.graphql";

type UseBasicDataProps = {
  fetch?: boolean;
};

export function isUserFragment(obj: any): obj is BasicUserFragmentFragment {
  return obj?.__typename === "User";
}

export function isWorkspaceFragment(
  obj: any,
): obj is GetBasicDataWorkspaceFragmentFragment {
  return obj?.__typename === "Workspace";
}

function isFileFragment(obj: any): obj is BasicFileFragmentFragment {
  return obj?.__typename === "File";
}

type RouteParams = {
  workspace?: string;
  fileId?: string;
};

const OrderByTier = {
  FREE: 0,
  PRO: 1,
  STUDIO: 2,
  ENTERPRISE: 3,
};

const orderMembersShipsByTier = (memberships) => {
  const sortedMemberShips = [...memberships];

  sortedMemberShips.sort((a, b) => {
    const aVal = OrderByTier[a.workspace.planTier];
    const bVal = OrderByTier[b.workspace.planTier];
    return aVal > bVal ? -1 : 1;
  });

  return sortedMemberShips;
};

export default function useBasicData(props?: UseBasicDataProps) {
  const params = useParams<keyof RouteParams>() as RouteParams;
  const { activeWorkspaceName, setActiveWorkspaceName } = useNavigationStore();
  const workspaceName = params.workspace;

  const hasWorkspaceName = !!workspaceName && workspaceName !== "me";

  const [query, refreshBasicData] = useQuery({
    query: GetBasicData,
    variables: {
      hasWorkspaceName,
      workspaceName: workspaceName ?? "",
      activeWorkspaceName: activeWorkspaceName ?? "",
      hasActiveWorkspaceName: !!activeWorkspaceName,
      hasFileId: !!params.fileId,
      fileId: params.fileId ?? "",
    },
    requestPolicy: props?.fetch ? "network-only" : "cache-only",
  });

  const respMe = query.data?.me;
  const respWorkspace = query.data?.workspace;
  const respActiveWorkspace = query.data?.activeWorkspace;
  const respFile = query.data?.file;
  const user = isUserFragment(respMe) ? respMe : null;
  const userError = isError(respMe) ? respMe : null;
  const workspace = isWorkspaceFragment(respWorkspace) ? respWorkspace : null;
  const workspaceError = isError(respWorkspace) ? respWorkspace : null;
  const activeWorkspace = isWorkspaceFragment(respActiveWorkspace)
    ? respActiveWorkspace
    : null;
  const activeWorkspaceError = isError(respActiveWorkspace)
    ? respActiveWorkspace
    : null;
  const file = isFileFragment(respFile) ? respFile : null;
  const fileError = isError(respFile) ? respFile : null;
  const featureFlags = user ? extractFeatureFlags(user.featureFlags) : {};

  useEffect(() => {
    // Assigns active workspace in to local storage only if user is switching to a context of a workspace that they are part of
    // If there is no active workspace assigned, we use the first workspace in user's memberships

    if (user) {
      const memberships = getConnectionNodesTyped(user.memberships);
      const isPartOfWorkspace = memberships.some(
        ({ workspace }) => workspace.name === workspaceName,
      );

      // Handles not set yet case, logged out case, and when user gets removed from workspace
      const isPartOfActiveWorkspace = memberships.some(
        ({ workspace }) => workspace.name === activeWorkspaceName,
      );

      if (!isPartOfActiveWorkspace) {
        const membershipsOrderedByTier = orderMembersShipsByTier(memberships);
        const firstWorkspaceName = membershipsOrderedByTier[0].workspace.name;
        setActiveWorkspaceName(firstWorkspaceName);
      } else if (isPartOfWorkspace) {
        setActiveWorkspaceName(workspaceName);
      }
    }
  }, [user, workspaceName, activeWorkspaceName]);

  return {
    fetching: query.fetching,
    error: query.error,
    user,
    userError,
    workspace: workspace || file?.workspace || null,
    workspaceError,
    activeWorkspace,
    activeWorkspaceError,
    file,
    fileError,
    featureFlags,
    refreshBasicData,
  };
}
