import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { Icon, Typography } from "../../../../../ui-v2";

interface ConnectionIndicatorButtonProps {
  expanded: boolean;
}

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transition:
    "margin 300ms ease-out, width 300ms ease-out, minHeight 300ms ease-out",
  display: "flex",
  gap: 2,
  fontSize: 12,
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1, 2),
  width: expanded ? "unset" : theme.spacing(7),
  minHeight: expanded ? "unset" : theme.spacing(7),
  margin: expanded ? "0 4px" : "-4px 0 0 -28px",
  backgroundColor: "transparent",
  borderRadius: 9999,

  svg: {
    color: theme.palette.warning[400],
    marginLeft: expanded ? "-1px" : 0,
    marginTop: expanded ? "-1px" : 0,
    marginRight: expanded ? "4px" : 0,
  },

  "& .MuiTypography-root": {
    display: expanded ? "flex" : "none",
    color: theme.palette.secondary["200/75"],
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },

  borderWidth: 1,
  borderColor: theme.palette.warning[300],
  borderStyle: "solid",
  animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) 1",
  animationFillMode: "forwards",

  "@keyframes ping": {
    "75%, 100%": {
      borderColor: theme.palette.secondary[700],
    },
  },
}));

export const ConnectionIndicator = (props: ConnectionIndicatorButtonProps) => {
  const { expanded } = props;
  return (
    <Container expanded={expanded}>
      <Icon
        size="small"
        icon={icon({
          name: "wifi-slash",
          family: "sharp",
          style: "regular",
        })}
      />
      <Typography variant="mini">Offline</Typography>
    </Container>
  );
};
