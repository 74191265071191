// Provider wrapper
import { yupResolver } from "@hookform/resolvers/yup";
import { createContext, ReactNode } from "react";
import { useRef } from "react";
import { Resolver, useForm } from "react-hook-form";

import {
  CreateFormType,
  DEFAULT_CREATE_FORM_VALUES,
  DEFAULT_UPSCALE_FORM_VALUES,
  UpscaleFormType,
} from "../constants";
import { createValidationSchema } from "../createValidationSchema";
import { upscaleValidationSchema } from "../upscaleValidationSchema";
import {
  createInferenceFormStore,
  InferenceFormProps,
  InferenceFormStore,
} from "./store";

export const InferenceFormContext = createContext<InferenceFormStore | null>(
  null,
);

interface InferenceFormProviderProps extends InferenceFormProps {
  children?: ReactNode;
}

export function InferenceFormProvider({
  children,
  ...props
}: InferenceFormProviderProps) {
  // initialize forms with default values - these forms do not change once initialized
  const createInferenceForm = useForm<CreateFormType>({
    resolver: yupResolver(createValidationSchema) as Resolver<CreateFormType>,
    mode: "onBlur",
    defaultValues: DEFAULT_CREATE_FORM_VALUES,
  });
  const upscaleInferenceForm = useForm<UpscaleFormType>({
    resolver: yupResolver(upscaleValidationSchema) as Resolver<UpscaleFormType>,
    mode: "onBlur",
    defaultValues: DEFAULT_UPSCALE_FORM_VALUES,
  });

  const storeRef = useRef<InferenceFormStore>();
  if (!storeRef.current) {
    // create the store only once
    storeRef.current = createInferenceFormStore({
      ...props,
      activeMode: "CREATE",
      createInferenceForm,
      upscaleInferenceForm,
    });
  }
  return (
    <InferenceFormContext.Provider value={storeRef.current}>
      {children}
    </InferenceFormContext.Provider>
  );
}
