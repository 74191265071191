export const ReferenceImageIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6875 6.54907V14.4503L10.307 8.4292L7.31289 12.621L5.91289 11.221L3.3125 14.1112V3.20654H11.3401V1.89404H2V15.894H16V6.54907H14.6875Z"
      fill="currentColor"
    />
    <path
      d="M6.86558 6.65347C7.11172 6.89961 7.25 7.23345 7.25 7.58154C7.25 7.92964 7.11172 8.26348 6.86558 8.50962C6.61944 8.75576 6.2856 8.89404 5.9375 8.89404C5.5894 8.89404 5.25556 8.75576 5.00942 8.50962C4.76328 8.26348 4.625 7.92964 4.625 7.58154C4.625 7.23345 4.76328 6.89961 5.00942 6.65347C5.25556 6.40732 5.5894 6.26904 5.9375 6.26904C6.2856 6.26904 6.61944 6.40732 6.86558 6.65347Z"
      fill="currentColor"
    />
    <path
      d="M15.9963 5.04907H14.6838V3.20532H12.8401V1.89282H14.6838V0.0490723H15.9963V1.89282H17.8401V3.20532H15.9963V5.04907Z"
      fill="currentColor"
    />
  </svg>
);
