import { styled } from "@mui/system";

import { Tooltip, TooltipProps } from "../../ui-v2";

interface DisabledTooltipProps extends TooltipProps {
  disabled: boolean;
}

const DisabledContainer = styled("div")({
  cursor: "not-allowed",
  position: "relative",
  zIndex: 0,
});

const DisabledArea = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
});

export const DisabledTooltip = ({
  disabled,
  children,
  ...rest
}: DisabledTooltipProps) => {
  if (!disabled) {
    return children;
  }

  return (
    <Tooltip {...rest}>
      <DisabledContainer>
        <DisabledArea />
        {children}
      </DisabledContainer>
    </Tooltip>
  );
};
