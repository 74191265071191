import { MenuItemProps } from "@mui/material/MenuItem";
import React, { forwardRef } from "react";

import { DisabledTooltip } from "../../inference-form-v2/common/DisabledTooltip";
import { ListItemIcon, ListItemText, MenuItem, Typography } from "..";

export interface IconMenuItemProps extends MenuItemProps {
  label?: string;
  renderLabel?: () => React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rightLabel?: string;
  href?: string;
  target?: string;
  disabledTooltipTitle?: string;
}

export const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  (
    {
      label,
      renderLabel,
      leftIcon,
      rightIcon,
      rightLabel,
      disabled = false,
      disabledTooltipTitle = "",
      ...rest
    }: IconMenuItemProps,
    ref,
  ) => {
    return (
      <DisabledTooltip disabled={disabled} title={disabledTooltipTitle}>
        <MenuItem disabled={disabled} {...rest} ref={ref}>
          {leftIcon ? (
            <ListItemIcon sx={{ color: "inherit" }}>{leftIcon}</ListItemIcon>
          ) : null}
          {renderLabel ? (
            renderLabel()
          ) : (
            <ListItemText
              sx={{
                color: "inherit",
              }}
              primaryTypographyProps={{
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            >
              {label}
            </ListItemText>
          )}
          {rightIcon ? (
            <ListItemIcon sx={{ color: "inherit", justifyContent: "end" }}>
              {rightIcon}
            </ListItemIcon>
          ) : null}
          {rightLabel ? (
            <Typography color="inherit" fontSize="inherit">
              {rightLabel}
            </Typography>
          ) : null}
        </MenuItem>
      </DisabledTooltip>
    );
  },
);
