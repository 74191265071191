import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

type useOnValueChangedProps = {
  value?: any;
  onChanged?: () => void;
  runOnFirstRender?: boolean;
};

const useOnValueChanged = (props: useOnValueChangedProps) => {
  const { value, onChanged, runOnFirstRender = false } = props;
  const valueRef = useRef(value);

  if (!isEqual(valueRef.current, value)) {
    valueRef.current = value;
    onChanged();
  }

  useEffect(() => {
    if (runOnFirstRender) {
      onChanged();
    }
  }, []);
};

export default useOnValueChanged;
