import { UseFormReturn } from "react-hook-form";
import { createStore } from "zustand";

import { SessionInferenceMode } from "../../graphql/schema.graphql";
import { CreateFormType, UpscaleFormType } from "../constants";

export interface InferenceFormProps {}

export interface InferenceFormInternalProps extends InferenceFormProps {
  activeMode: SessionInferenceMode;
  createInferenceForm: UseFormReturn<CreateFormType>;
  upscaleInferenceForm: UseFormReturn<UpscaleFormType>;
}

export interface InferenceFormState extends InferenceFormInternalProps {
  setActiveMode: (mode: SessionInferenceMode) => void;
}

export const createInferenceFormStore = (
  initProps: InferenceFormInternalProps,
) => {
  return createStore<InferenceFormState>()((set) => ({
    ...initProps,
    setActiveMode: (activeMode) => set(() => ({ activeMode })),
  }));
};

export type InferenceFormStore = ReturnType<typeof createInferenceFormStore>;
