import { useContext, useEffect, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { GraphqlWsClientContext } from "../components/graphql/GraphqlWsContext";

const CONNECTION_GRACE_PERIOD_DELAY_MS = 5000;

export const useIsOnline = (): boolean => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const { connectionInfo } = useContext(GraphqlWsClientContext);
  const [isWsConnected, setIsWsConnected] = useState(false);
  const [isWsConnectionExpected, setIsWsConnectionExpected] = useState(false);
  const debouncedSetConnectionExpected = useDebounceCallback(
    setIsWsConnectionExpected,
    CONNECTION_GRACE_PERIOD_DELAY_MS,
  );

  // Browser online state
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    window.addEventListener("online", handleOnline);
    return () => window.removeEventListener("online", handleOnline);
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("offline", handleOffline);
    return () => window.removeEventListener("offline", handleOffline);
  }, []);

  // Websocket online state
  useEffect(() => {
    setIsWsConnected(connectionInfo?.status === "connected");
    if (!!connectionInfo && connectionInfo.status !== "disconnected") {
      debouncedSetConnectionExpected(true);
    } else {
      debouncedSetConnectionExpected.cancel();
      setIsWsConnectionExpected(false);
    }
  }, [connectionInfo?.status]);

  if (isWsConnectionExpected) {
    return isOnline && isWsConnected;
  }

  return isOnline;
};
