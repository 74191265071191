import { useCallback } from "react";
import {
  NavigateOptions,
  To,
  useNavigate as useNavigateReactRouter,
} from "react-router-dom";

type StrictNavigateOptions<State> = Omit<NavigateOptions, "state"> & {
  state: State;
};

type NavigateWithDataFunction = {
  (to: To | number, options?: NavigateOptions): void;
  <State>(to: To | number, options: StrictNavigateOptions<State>): void;
};

export default function useNavigate(): NavigateWithDataFunction {
  const navigate = useNavigateReactRouter();

  return useCallback(
    ((to: To | number, options?: NavigateOptions) => {
      if (typeof to === "number") {
        navigate(to);
        return;
      }

      navigate(to, options);
    }) as NavigateWithDataFunction,
    [navigate],
  );
}
