import { styled } from "@mui/system";

export const IndicatorContainer = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary[200],
  backgroundColor: theme.palette.secondary[950],
  borderBottomLeftRadius: 6,
  position: "absolute",
  top: 0,
  right: 0,
  lineHeight: 1,
  aspectRatio: 1,
  height: 24,
  width: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > svg": {
    color: theme.palette.secondary["200"],
  },
}));
