import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AnyVariables,
  OperationResult,
  UseMutationState,
  UseQueryState,
} from "urql";

import { ErrorWithCodeAndMessage, hasTypename } from "../utils/graphql";
import useNextParam from "./useNextParam";
import useShowSnackbar from "./useShowSnackbar";

type UrqlState<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
> =
  | UseQueryState<Data, Variables>
  | UseMutationState<Data, Variables>
  | OperationResult<Data, Variables>;

type GetResponseFromStateArgs<
  Data extends { [key in string]?: any },
  Variables extends AnyVariables = AnyVariables,
> = {
  state: UrqlState<Data, Variables>;
  redirectOnError?: boolean;
  successMessage?: string;
  errorMessage?: string;
  showSnackbarOnError?: boolean;
  getErrorMessage?: (err: ErrorWithCodeAndMessage) => string | null;
  onError?: (err: ErrorWithCodeAndMessage) => void;
};

type ResponseData<Data extends { [key in string]?: any }> = {
  [key in keyof Data]?: Exclude<Data[key], { __typename: "Error" }>;
};

type GetResponseFromStateResponse<Data extends { [key in string]?: any }> = {
  data: ResponseData<Data>;
  error?: ErrorWithCodeAndMessage;
};

function defaultGetErrorMessage({ message }: ErrorWithCodeAndMessage) {
  return message;
}

export function useUrqlResponseHandler() {
  const nextParam = useNextParam();
  const { showSuccess, showError } = useShowSnackbar();
  const navigate = useNavigate();
  const errorHandled = useRef<boolean>(false);

  const getResponseFromState = useCallback(
    <
      Data extends { [key in string]?: any },
      Variables extends AnyVariables = AnyVariables,
    >({
      state,
      redirectOnError,
      successMessage,
      errorMessage,
      showSnackbarOnError,
      getErrorMessage = defaultGetErrorMessage,
      onError,
    }: GetResponseFromStateArgs<Data, Variables>) => {
      const response: GetResponseFromStateResponse<Data> = {
        data: {},
      };
      if (state.error) {
        response.error = {
          __typename: "Error",
          code: "NETWORK_ERROR",
          message: state.error.message,
        };
        if (!errorHandled.current && showSnackbarOnError) {
          showError(
            "Something went wrong connecting to the server. Please try again.",
          );
        }
        errorHandled.current = true;
        return response;
      }
      if (!state.data) {
        return response;
      }
      Object.keys(state.data).forEach((key: keyof Data) => {
        const dataValue = state.data[key];
        if (hasTypename("Error", dataValue)) {
          response.error = {
            __typename: "Error",
            code: dataValue.code,
            message: dataValue.message,
          };
          if (response.error.code === "INVALID_JWT") {
            navigate(`/login?next=${nextParam}`);
          } else if (redirectOnError) {
            navigate(`/error?code=${dataValue.code}&next=${nextParam}`);
          } else {
            if (!errorHandled.current) {
              if (errorMessage) {
                showError(errorMessage);
              } else if (showSnackbarOnError) {
                const message = getErrorMessage(response.error);
                if (message) {
                  showError(message);
                }
              }
              if (onError) {
                onError(response.error);
              }
            }
            errorHandled.current = true;
          }
        } else {
          response.data[key] = dataValue;
          if (successMessage) {
            showSuccess(successMessage);
          }
        }
      });
      return response;
    },
    [showError, navigate, nextParam],
  );

  return { getResponseFromState };
}
