import { useMemo } from "react";
import { useIntercom } from "react-use-intercom";

import { WorkspaceDropdown } from "../../../../../../features/nav-v2/components";
import { getConnectionNodesTyped } from "../../../../../../utils/relay";
import { AppMenuUser, AppMenuWorkspace } from "../../../../Nav";

interface WorkspaceSelectionButtonProps {
  workspace: AppMenuWorkspace;
  user: AppMenuUser;
}

export const WorkspaceSelectionButton = (
  props: WorkspaceSelectionButtonProps,
) => {
  const { workspace, user } = props;

  const memberships = useMemo(
    () => getConnectionNodesTyped(user?.memberships),
    [user.memberships],
  );

  const workspaces = useMemo(
    () => memberships.map((membership) => membership.workspace),
    [memberships],
  );

  const { showNewMessage } = useIntercom();

  if (!workspace || !workspace.myRole || !workspaces) {
    return null;
  }

  return (
    <WorkspaceDropdown
      allWorkspaces={workspaces}
      currentWorkspaceId={workspace.id}
      personalWorkspaceId={user?.personalWorkspace?.id}
      userEmail={user?.email}
      userFullName={user?.name}
      userProfilePictureUrl={user?.profilePicture}
      handleIntercomMessage={showNewMessage}
    />
  );
};
