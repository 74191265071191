export const DepthMapIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6875 3.3125V14.5563H3.3125V3.3125H14.6875ZM2 2V16H16V2H2Z"
      fill="currentColor"
    />
    <path
      d="M11.6875 6.3125V11.6875H6.3125V6.3125H11.6875ZM5 5V13H13V5H5Z"
      fill="currentColor"
    />
    <path
      d="M9.3125 8H8V9.3125V8.6875V10H9.3125H8.6875H10V8.6875V9.3125V8H8.6875H9.3125Z"
      fill="currentColor"
    />
  </svg>
);
