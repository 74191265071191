import { ComponentProps } from "react";
import { IntercomProvider } from "react-use-intercom";

import layerConfig from "../../utils/config";

type LayerIntercomProviderProps = Omit<
  ComponentProps<typeof IntercomProvider>,
  "appId"
>;

export const LayerIntercomProvider = (props: LayerIntercomProviderProps) => (
  <IntercomProvider {...props} appId={layerConfig.INTERCOM_APP_ID} autoBoot />
);
