import { useAuth0 } from "@auth0/auth0-react";
import { Client, createClient as createWSClient } from "graphql-ws";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export interface GraphqlWsConnectionInfo {
  status: "connecting" | "connected" | "disconnected" | "error";
  latencyMs?: number;
}

interface ExtendedWsClient extends Client {
  onReconnected(listener: () => void): () => void;
  reconnect: () => void;
}

export interface GraphqlWsClientContextType {
  client?: ExtendedWsClient;
  connectionInfo: GraphqlWsConnectionInfo;
}

export const GraphqlWsClientContext = createContext<GraphqlWsClientContextType>(
  { connectionInfo: { status: "disconnected" } },
);

const wsUrl = new URL(process.env.LAYER_GRAPHQL_URI, window.location.origin);
wsUrl.protocol = window.location.protocol === "https:" ? "wss" : "ws";

interface TimeoutState {
  timeout: ReturnType<typeof setTimeout>;
  resolve: () => void;
}

const getExponentialBackoffRetryWait = ({
  initialDelaySeconds,
  maxDelaySeconds,
  onTimeoutSet,
  onTimeoutCleared,
}: {
  initialDelaySeconds: number;
  maxDelaySeconds: number;
  onTimeoutSet?: (params: TimeoutState) => void;
  onTimeoutCleared?: () => void;
}): ((retries: number) => Promise<void>) => {
  return async function randomisedExponentialBackoff(retries) {
    let retryDelay = initialDelaySeconds * 1000;
    for (let i = 0; i < retries; i++) {
      retryDelay *= 2;
    }
    // add random timeout from 300ms to 3s
    retryDelay = Math.min(
      retryDelay + Math.floor(Math.random() * (3000 - 300) + 300),
      maxDelaySeconds * 1000,
    );
    await new Promise<void>((resolve) => {
      function resolveAndPropagate() {
        resolve();
        onTimeoutCleared?.();
      }
      const timeout = setTimeout(resolveAndPropagate, retryDelay);
      onTimeoutSet?.({ timeout, resolve });
      return timeout;
    });
  };
};

export const GraphqlWsClientProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [client, setClient] = useState<ExtendedWsClient>(null);
  const [connectionInfo, setConnectionInfo] =
    useState<GraphqlWsConnectionInfo>(null);

  return (
    <GraphqlWsClientContext.Provider
      value={{ client, connectionInfo: connectionInfo }}
    >
      <GraphqlWsClient
        url={wsUrl.toString()}
        keepAliveIntervalSeconds={10}
        keepAliveTimeoutSeconds={5}
        retryInitialDelaySeconds={1}
        retryMaxDelaySeconds={10}
        setConnectionInfo={setConnectionInfo}
        onClient={(client) => setClient(client)}
      />
      {children}
    </GraphqlWsClientContext.Provider>
  );
};

export const useWebSocketConnectionInfo = (): GraphqlWsConnectionInfo => {
  const { connectionInfo } = useContext(GraphqlWsClientContext);
  return connectionInfo;
};

export interface GraphqlWsClientProps {
  url: string;
  keepAliveIntervalSeconds: number;
  keepAliveTimeoutSeconds: number;
  retryInitialDelaySeconds: number;
  retryMaxDelaySeconds: number;
  onClient: (client: ExtendedWsClient) => void;
  setConnectionInfo: Dispatch<SetStateAction<GraphqlWsConnectionInfo>>;
}

export const GraphqlWsClient = ({
  url,
  keepAliveIntervalSeconds,
  keepAliveTimeoutSeconds,
  retryInitialDelaySeconds,
  retryMaxDelaySeconds,
  onClient,
  setConnectionInfo,
}: GraphqlWsClientProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [_, setTimeoutState] = useState<TimeoutState | null>(null);

  const client = useRef(null);

  const reconnect = useCallback(
    (client: Client) => {
      setTimeoutState((prev) => {
        if (prev) {
          const { timeout, resolve } = prev;
          clearTimeout(timeout);
          resolve();
        } else {
          client.terminate();
        }
        return null;
      });
    },
    [setTimeoutState],
  );

  useEffect(() => {
    const keepAliveState: { pingSentAt?: number; timeoutHandle?: any } = {};
    const reconnectedListeners: (() => void)[] = [];
    const wsClient = createWSClient({
      url,
      connectionParams: async () => ({
        Authorization: await getAccessTokenSilently(),
      }),
      shouldRetry: () => true,
      retryWait: getExponentialBackoffRetryWait({
        initialDelaySeconds: retryInitialDelaySeconds,
        maxDelaySeconds: retryMaxDelaySeconds,
        onTimeoutSet: setTimeoutState,
        onTimeoutCleared: () => setTimeoutState(null),
      }),
      retryAttempts: Number.MAX_VALUE,
      keepAlive: keepAliveIntervalSeconds * 1000,
      on: {
        connected: () => {
          let isAbruptlyClosed = false;
          setConnectionInfo((prev) => {
            if (prev.status === "error") {
              isAbruptlyClosed = true;
            }
            return {
              ...prev,
              status: "connected",
            };
          });
          if (isAbruptlyClosed) {
            reconnectedListeners.forEach((cb) => cb());
          }
        },
        connecting: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "connecting",
            };
          });
        },
        closed: (event) => {
          setConnectionInfo((prev) => {
            const status =
              (event as CloseEvent).code !== 1000 ? "error" : "disconnected";
            return {
              ...prev,
              status,
            };
          });
        },
        error: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "error",
            };
          });
        },
        ping: (received) => {
          if (!received /* sent */) {
            keepAliveState.pingSentAt = Date.now();
            keepAliveState.timeoutHandle = setTimeout(() => {
              wsClient.terminate();
            }, keepAliveTimeoutSeconds * 1000);
          }
        },
        pong: (received) => {
          if (received) {
            setConnectionInfo((prev) => {
              return {
                ...prev,
                latencyMs: Date.now() - keepAliveState.pingSentAt,
              };
            });
            clearTimeout(keepAliveState.timeoutHandle);
          }
        },
      },
    });
    client.current = wsClient;
    onClient({
      ...wsClient,
      onReconnected: (listener: () => void) => {
        reconnectedListeners.push(listener);
        return () => {
          reconnectedListeners.splice(
            reconnectedListeners.indexOf(listener),
            1,
          );
        };
      },
      reconnect: () => reconnect(wsClient),
    });
    return () => {
      wsClient.dispose();
    };
  }, [
    url,
    keepAliveIntervalSeconds,
    keepAliveTimeoutSeconds,
    retryInitialDelaySeconds,
    retryMaxDelaySeconds,
    reconnect,
    setTimeoutState,
  ]);

  return null;
};
