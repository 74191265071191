import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, styled } from "@mui/system";
import { useState } from "react";

import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "../../../../../ui-v2";
import { useNavigationStore } from "../../../../state";

const ButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transition: "margin-bottom 300ms ease-out",
  height: theme.spacing(7),
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginBottom: expanded ? theme.spacing(-8) : 0,
}));

const StyledButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expanded" && prop !== "isMenuOpen",
})<{
  expanded: boolean;
  isMenuOpen: boolean;
}>(({ theme, isMenuOpen }) => ({
  height: theme.spacing(7),
  width: theme.spacing(7),
  color: isMenuOpen
    ? theme.palette.secondary[100]
    : theme.palette.secondary[300],
  backgroundColor: isMenuOpen ? theme.palette.secondary[900] : "transparent",
  border: "none",
  boxShadow: isMenuOpen
    ? `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`
    : "inset 0 0 0 0.5px transparent",
  fontWeight: "400",

  "&:hover": {
    color: theme.palette.secondary[100],
    backgroundColor: theme.palette.secondary[900],
    boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`,
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const ExpandCollapseButton = () => {
  const { expanded, toggleExpanded } = useNavigationStore();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleResetToggle = () => {
    useNavigationStore.getState().resetExpanded();
    handleCloseMenu();
  };

  const isMenuOpen = Boolean(menuAnchor);

  return (
    <ButtonWrapper expanded={expanded}>
      <StyledButton
        expanded={expanded}
        isMenuOpen={isMenuOpen}
        variant="primary"
        size="medium"
        aria-label={expanded ? "Collapse sidebar" : "Expand sidebar"}
        onClick={toggleExpanded}
        onContextMenu={handleContextMenu}
      >
        <Box
          sx={{
            transition: "transform 0.3s ease-out",
            transform: expanded ? "rotate(0deg)" : "rotate(-180deg)",
          }}
        >
          <Icon
            style={{ marginLeft: "-1px" }}
            size="small"
            icon={icon({
              name: "angles-left",
              family: "sharp",
              style: "regular",
            })}
          />
        </Box>
      </StyledButton>
      <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={handleResetToggle}>
          <ListItemIcon>
            <Icon
              size="medium"
              icon={icon({
                name: "arrow-rotate-left",
                family: "sharp",
                style: "regular",
              })}
            />
          </ListItemIcon>
          <ListItemText>Use default behaviour</ListItemText>
        </MenuItem>
      </Menu>
    </ButtonWrapper>
  );
};
