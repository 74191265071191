import { ChipProps } from "@mui/material";
import { SxProps } from "@mui/system";

import { Chip } from "..";

interface ShortcutIndicatorProps {
  keys: string[];
  size?: "small" | "medium" | "fixedMedium";
  sx?: SxProps;
  color?: ChipProps["color"];
}

export const ShortcutIndicator = ({
  keys,
  size = "medium",
  sx,
  color = "secondary",
}: ShortcutIndicatorProps) => {
  return (
    <Chip
      label={keys.join(" ")}
      variant="filled"
      color={color}
      size="small"
      sx={{
        ...sx,
        padding: size === "small" ? 0.5 : 2,
        maxWidth: size === "fixedMedium" ? "35px" : null,
        borderRadius: "2px",
        "& .MuiChip-label": {
          textOverflow: "clip",
          whiteSpace: "nowrap",
          minWidth: size === "fixedMedium" ? "35px" : null,
          textAlign: "center",
        },
      }}
    />
  );
};
