import * as yup from "yup";

import { roundTo } from "../../utils/numbers";
import {
  MAX_DENOISING_STEPS,
  MAX_PROMPT_STRENGTH,
  MAX_SEED,
  MAX_UPSCALE_CREATIVITY,
  MAX_UPSCALE_DIMENSION,
  MAX_UPSCALE_FACTOR,
  MAX_UPSCALE_RESEMBLANCE,
  MIN_DENOISING_STEPS,
  MIN_PROMPT_STRENGTH,
  MIN_SEED,
  MIN_UPSCALE_CREATIVITY,
  MIN_UPSCALE_FACTOR,
  MIN_UPSCALE_RESEMBLANCE,
  UPSCALE_FORM_FIELDS,
  UpscaleFormType,
} from "./constants";

export const upscaleValidationSchema = yup.object().shape({
  [UPSCALE_FORM_FIELDS.UPSCALE_TARGET_FILE]: yup
    .object()
    .required("Upscale reference file is required"),
  [UPSCALE_FORM_FIELDS.PROMPT_TEXT]: yup.string(),
  [UPSCALE_FORM_FIELDS.NEGATIVE_PROMPT_TEXT]: yup.string(),
  [UPSCALE_FORM_FIELDS.SEED]: yup
    .number()
    .nullable()
    .test(
      "is-valid-seed",
      `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
      (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
    ),
  [UPSCALE_FORM_FIELDS.PROMPT_STRENGTH]: yup
    .number()
    .nullable()
    .min(
      MIN_PROMPT_STRENGTH,
      `Prompt strength must be at least ${MIN_PROMPT_STRENGTH}.`,
    )
    .max(
      MAX_PROMPT_STRENGTH,
      `Prompt strength must be at most ${MAX_PROMPT_STRENGTH}.`,
    ),
  [UPSCALE_FORM_FIELDS.DENOISING_STEPS]: yup
    .number()
    .nullable()
    .min(
      MIN_DENOISING_STEPS,
      `Denoising steps must be at least ${MIN_DENOISING_STEPS}.`,
    )
    .max(
      MAX_DENOISING_STEPS,
      `Denoising steps must be at most ${MAX_DENOISING_STEPS}.`,
    ),
  [UPSCALE_FORM_FIELDS.UPSCALE_FACTOR]: yup
    .number()
    .required("Upscale factor is required")
    .min(MIN_UPSCALE_FACTOR, `Upscale factor at least ${MIN_UPSCALE_FACTOR}.`)
    .max(
      MAX_UPSCALE_FACTOR,
      `Scale factor must be at most ${MAX_UPSCALE_FACTOR}.`,
    )
    .when(
      [UPSCALE_FORM_FIELDS.UPSCALE_TARGET_FILE],
      ([targetFile]: [UpscaleFormType["upscaleTargetFile"]], schema) => {
        let maxWidthScale = MAX_UPSCALE_FACTOR;
        let maxHeightScale = MAX_UPSCALE_FACTOR;

        if (targetFile && targetFile.width && targetFile.height) {
          maxWidthScale = MAX_UPSCALE_DIMENSION / targetFile.width;
          maxHeightScale = MAX_UPSCALE_DIMENSION / targetFile.height;

          const maxScaleFactor = roundTo(
            Math.min(maxWidthScale, maxHeightScale, MAX_UPSCALE_FACTOR),
            1,
            "floor",
          );

          if (maxScaleFactor < MAX_UPSCALE_FACTOR) {
            return schema.max(
              maxScaleFactor,
              `Scale factor must be at most ${maxScaleFactor} to stay within ${MAX_UPSCALE_DIMENSION} pixels.`,
            );
          }
        }
      },
    ),
  [UPSCALE_FORM_FIELDS.CREATIVITY]: yup
    .number()
    .nullable()
    .min(
      MIN_UPSCALE_CREATIVITY,
      `Upscale creativity must be at least ${MIN_UPSCALE_CREATIVITY}.`,
    )
    .max(
      MAX_UPSCALE_CREATIVITY,
      `Upscale creativity must be at most ${MAX_UPSCALE_CREATIVITY}.`,
    ),
  [UPSCALE_FORM_FIELDS.RESEMBLANCE]: yup
    .number()
    .nullable()
    .min(
      MIN_UPSCALE_RESEMBLANCE,
      `Upscale resemblance must be at least ${MIN_UPSCALE_RESEMBLANCE}.`,
    )
    .max(
      MAX_UPSCALE_RESEMBLANCE,
      `Upscale resemblance must be at most ${MAX_UPSCALE_RESEMBLANCE}.`,
    ),
}) as yup.ObjectSchema<UpscaleFormType>;
