export const CannyEdgeIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 2H10V3.3125H14.6875V8H16V2Z" fill="currentColor" />
    <path d="M2 16V10H3.3125V14.5563H8V16H2Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.09375 5.28125L2.65625 5.71875L11.1875 14.25L14.25 15.125L15.125 16L16 15.125L15.125 14.25L14.25 11.1875L8.26172 5.19648L7.33203 4.26953L5.71875 2.65625L5.28125 3.09375L4.1875 2L2 4.1875L3.09375 5.28125ZM12.202 10.9961L10.9961 12.202L4.51289 5.71875L5.71875 4.51289L12.202 10.9961ZM13.5637 13.5664L11.9504 13.1043L13.1016 11.9531L13.5637 13.5664Z"
      fill="currentColor"
    />
  </svg>
);
