import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import { useIntercom } from "react-use-intercom";

import { Box, Button, Icon, Typography } from "../../../../../ui-v2";
import { useNavigationStore } from "../../../../state";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  justifyContent: "flex-start",
  border: "none",
  borderRadius: expanded ? "4px" : "99px",
  fontWeight: "400",

  "&:hover": {
    border: "none",
  },

  "& .MuiButton-startIcon": {
    transition: "margin 0.3s ease-out",
    marginLeft: expanded ? theme.spacing(-2) : "-8.5px",
    marginRight: expanded ? theme.spacing(1) : 0,
    fontSize: "16px",
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const IntercomButton = (props) => {
  const { show, hide, isOpen } = useIntercom();
  const { expanded } = useNavigationStore();

  const handleIntercomToggle = (event) => {
    event.preventDefault();

    if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  const getLabel = () => {
    return isOpen ? "Close Chat" : "Talk To Us";
  };

  return (
    <Tooltip
      title={expanded ? "" : getLabel()}
      enterDelay={500}
      placement="right"
      arrow
    >
      <StyledButton
        {...props}
        expanded={expanded}
        onClick={handleIntercomToggle}
        variant={isOpen ? "contained" : "text"}
        color="secondary"
        fullWidth
        startIcon={
          <Box
            sx={{
              height: 28,
              width: 28,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isOpen && (
              <Icon
                icon={icon({
                  name: "message-dots",
                  family: "sharp",
                  style: "regular",
                })}
              />
            )}
            {isOpen && (
              <Icon
                icon={icon({
                  name: "message-dots",
                  family: "sharp",
                  style: "solid",
                })}
              />
            )}
          </Box>
        }
      >
        {expanded && (
          <Typography variant="small" fontWeight={500} color="secondary.100">
            {getLabel()}
          </Typography>
        )}
      </StyledButton>
    </Tooltip>
  );
};
