import { useContext } from "react";
import { useStore } from "zustand";

import { InferenceFormContext } from "./context";
import { InferenceFormState } from "./store";

export function useInferenceFormStore<T extends Partial<InferenceFormState>>(
  selector?: (state: InferenceFormState) => T,
): T {
  const store = useContext(InferenceFormContext);
  if (!store)
    throw new Error("Missing InferenceFormContext.Provider in the tree");
  return useStore(store, selector);
}
