import {
  icon,
  regular,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, styled } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useBasicData } from "../../hooks";
import { RelayConnection } from "../../utils/relay";
import {
  User,
  Workspace,
  WorkspaceMembership,
} from "../graphql/schema.graphql";
import { useInferenceFormStore } from "../inference-form-v2/state";
import { Icon } from "../ui-v2";
import { ForgeIcon, UpscaleIcon } from "../ui-v2/icons";
import { NavFooter } from "./components/NavFooter/NavFooter";
import { NavHeader } from "./components/NavHeader/NavHeader";
import { NavItemsContainer } from "./components/NavItemsContainer/NavItemsContainer";
import { useNavigationStore } from "./state";

const SIDEBAR_WIDTH = 220;
const SIDEBAR_COLLAPSED_WIDTH = 56;
const NON_TEAM_WORKSPACES = ["FREE", "PRO"];

interface NavProps {
  isDefaultCollapsed: boolean;
}

export type AppMenuWorkspace = Pick<
  Workspace,
  "__typename" | "id" | "name" | "profilePicture" | "myRole" | "myPermissions"
>;

export type AppMenuMembership = Pick<
  WorkspaceMembership,
  "__typename" | "id"
> & {
  workspace: AppMenuWorkspace;
};

export type AppMenuUser = Pick<
  User,
  "__typename" | "profilePicture" | "name" | "email"
> & {
  personalWorkspace?: AppMenuWorkspace;
  memberships: RelayConnection<AppMenuMembership>;
};

export type CreateMenuItemId = "forge" | "upscale" | "canvas";

export type AppMenuItem = {
  id: string;
  name: string;
  route: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  dataCy?: string;
  state?: object;
};

const NavContainer = styled("nav", {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ expanded }) => ({
  position: "fixed",
  width: expanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
  height: "100%",
  transition: "width 300ms ease-out",
  zIndex: 200,
}));

const ContentSpacer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ expanded }) => ({
  width: expanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
  flexShrink: 0,
  transition: "width 300ms ease-out",
}));

const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  height: "100%",
  width: "100%",
  transition: "padding-left 300ms ease-out, padding-right 300ms ease-out",
  overflowY: "auto",
  overflowX: "clip",
  color: theme.palette.secondary[300],
  display: "flex",
  flexDirection: "column",
  paddingLeft: expanded ? 12 : 14,
  paddingRight: expanded ? 12 : 14,
}));

export const Nav = (props: NavProps) => {
  const { isDefaultCollapsed } = props;
  const location = useLocation();
  const { user, activeWorkspace: workspace } = useBasicData();
  const { expanded, userToggled, setExpanded } = useNavigationStore();
  const { activeMode } = useInferenceFormStore();

  useEffect(() => {
    const updateSidebarState = () => {
      const { expanded: currentExpanded, userToggled } =
        useNavigationStore.getState();
      const newExpanded = userToggled ? currentExpanded : !isDefaultCollapsed;
      if (currentExpanded !== newExpanded) {
        setExpanded(newExpanded);
      }
    };
    updateSidebarState();
  }, [location.pathname, isDefaultCollapsed, userToggled]);

  const getAppMenuItems = (workspace: AppMenuWorkspace): AppMenuItem[] => {
    if (!workspace) return [];

    const items: AppMenuItem[] = [
      {
        id: "home",
        name: "Home",
        route: `/${workspace?.name}`,
        icon: (
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "regular",
            })}
          />
        ),
        activeIcon: (
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "solid",
            })}
          />
        ),
        disabled: false,
      },
    ];

    if (workspace?.myPermissions.includes("asset:view")) {
      items.push({
        id: "drive",
        name: "Drive",
        route: `/${workspace.name}/drive`,
        icon: (
          <Icon
            icon={icon({
              name: "layer-group",
              family: "sharp",
              style: "regular",
            })}
          />
        ),
        activeIcon: (
          <Icon
            icon={icon({
              name: "layer-group",
              family: "sharp",
              style: "solid",
            })}
          />
        ),
        disabled: false,
      });
    }
    items.push({
      id: "styles",
      name: "Styles",
      route: `/${workspace.name}/styles`,
      icon: <Icon icon={regular("palette")} />,
      activeIcon: <Icon icon={solid("palette")} />,
      disabled: false,
    });

    if (
      workspace?.myPermissions.includes("inference:view_history") ||
      workspace?.myPermissions.includes("style:view")
    ) {
      items.push({
        id: "prompts",
        name: "History",
        route: `/${workspace.name}/prompts`,
        icon: (
          <Icon
            icon={icon({
              name: "clock-rotate-left",
              family: "sharp",
              style: "regular",
            })}
          />
        ),
        activeIcon: (
          <Icon
            icon={icon({
              name: "clock-rotate-left",
              family: "sharp",
              style: "solid",
            })}
          />
        ),
        disabled: false,
      });
    }
    return items;
  };

  const getCreateMenuItems = (workspace: AppMenuWorkspace) => {
    if (!workspace) return [];
    const createMenuItems: AppMenuItem[] = [];

    createMenuItems.push({
      id: "forge",
      name: "Forge",
      route: `/${workspace.name}/session/new`,
      icon: <ForgeIcon weight="regular" />,
      activeIcon: <ForgeIcon weight="solid" />,
    });

    createMenuItems.push({
      id: "upscale",
      name: "Upscale",
      route: `/${workspace.name}/session/new`,
      state: {
        mode: "UPSCALE",
      },
      icon: <UpscaleIcon fontSize="12" />,
      activeIcon: <UpscaleIcon fontSize="12" />,
    });

    createMenuItems.push({
      id: "canvas",
      name: "Canvas",
      route: `/${workspace.name}/assets/new`,
      icon: <Icon icon={regular("pencil")} />,
      activeIcon: <Icon icon={solid("pencil")} />,
    });

    return createMenuItems;
  };

  const appMenuItems = useMemo(() => getAppMenuItems(workspace), [workspace]);
  const createMenuItems = useMemo(
    () => getCreateMenuItems(workspace),
    [workspace],
  );

  if (!workspace) {
    return null;
  }

  return (
    <>
      <ContentSpacer expanded={expanded} />
      <NavContainer expanded={expanded} data-cy="nav-sidebar">
        <ContentContainer expanded={expanded}>
          <NavHeader workspace={workspace} user={user} />
          <NavItemsContainer
            items={appMenuItems}
            createMenuItems={createMenuItems}
            isPersonalWorkspace={NON_TEAM_WORKSPACES.includes(
              workspace.planTier,
            )}
            inferenceMode={activeMode}
          />
          <NavFooter />
        </ContentContainer>
      </NavContainer>
    </>
  );
};
