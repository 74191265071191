import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

import { Icon, ListItemIcon, Menu, MenuItem, Typography } from "../../..";
import { Anchor, StyleActions, StyleStatus } from "../../types";

interface StyleActionsMenuProps extends StyleActions {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  styleStatus: StyleStatus;
  anchor?: Anchor;
}

export const StyleActionsMenu = (props: StyleActionsMenuProps) => {
  const {
    open,
    anchor,
    onClose,
    onEdit,
    onForge,
    onUnlink,
    onView,
    onDelete,
    onImport,
    onDuplicate,
    onCancelTraining,
  } = props;

  const handleMenuOptionSelect =
    (fn: (event: React.MouseEvent) => void) => (event: React.MouseEvent) => {
      onClose(event);

      if (fn) {
        fn(event);
      }
    };

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorPosition={{
        top: anchor ? anchor.y : 0,
        left: anchor ? anchor.x : 0,
      }}
      anchorReference="anchorPosition"
    >
      {onForge && (
        <MenuItem onClick={handleMenuOptionSelect(onForge)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("arrow-up-right")} />
          </ListItemIcon>
          <Typography variant="inherit">Forge with Style</Typography>
        </MenuItem>
      )}
      {onView && (
        <MenuItem onClick={handleMenuOptionSelect(onView)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("location")} />
          </ListItemIcon>
          <Typography variant="inherit">View Style</Typography>
        </MenuItem>
      )}
      {onCancelTraining && (
        <MenuItem onClick={handleMenuOptionSelect(onCancelTraining)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("circle-stop")} />
          </ListItemIcon>
          <Typography variant="inherit">Cancel</Typography>
        </MenuItem>
      )}
      {onEdit && (
        <MenuItem onClick={handleMenuOptionSelect(onEdit)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("pencil-square")} />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
      )}
      {onImport && (
        <MenuItem onClick={handleMenuOptionSelect(onImport)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("file-import")} />
          </ListItemIcon>
          <Typography variant="inherit">Import style</Typography>
        </MenuItem>
      )}
      {onUnlink && (
        <MenuItem onClick={handleMenuOptionSelect(onUnlink)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("unlink")} />
          </ListItemIcon>
          <Typography variant="inherit">Unlink style</Typography>
        </MenuItem>
      )}
      {onDuplicate && (
        <MenuItem onClick={handleMenuOptionSelect(onDuplicate)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("copy")} />
          </ListItemIcon>
          <Typography variant="inherit">Duplicate</Typography>
        </MenuItem>
      )}
      {onDelete && (
        <MenuItem onClick={handleMenuOptionSelect(onDelete)}>
          <ListItemIcon>
            <Icon size="medium" icon={regular("trash")} />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};
