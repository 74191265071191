import { Box, styled } from "@mui/system";

import { useIsOnline } from "../../../../hooks/useIsOnline";
import { AppMenuUser, AppMenuWorkspace } from "../../Nav";
import { useNavigationStore } from "../../state";
import { ConnectionIndicator } from "./components/ConnectionIndicator/ConnectionIndicator";
import { ExpandCollapseButton } from "./components/ExpandCollapseButton/ExpandCollapseButton";
import { WorkspaceSelectionButton } from "./components/WorkspaceSelectionButton/WorkspaceSelectionButton";

const EXPANDED_GAP = "7px";

interface NavHeaderProps {
  workspace: AppMenuWorkspace;
  user: AppMenuUser;
}

const SidebarHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded" && prop !== "isOnline",
})<{ expanded: boolean; isOnline: boolean }>(
  ({ theme, expanded, isOnline }) => ({
    width: "100%",
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    transition: "height 300ms ease-out, gap 300ms ease-out",
    color: theme.palette.secondary[300],
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    ...(expanded
      ? {
          height: theme.spacing(10),
          gap: theme.spacing(1),
          paddingBottom: theme.spacing(1.5),
        }
      : {
          height: !isOnline ? "102px" : "70px",
          gap: EXPANDED_GAP,
        }),
  }),
);

const WorkspaceSelectionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded" && prop !== "isOnline",
})<{ expanded: boolean; isOnline: boolean }>(({ expanded, isOnline }) => ({
  userSelect: "none",
  transition: "margin-top 300ms ease-out, margin-bottom 300ms ease-out",
  flexGrow: 1,
  flexShrink: expanded ? 1 : 0,
  overflow: "hidden",
  ...(expanded
    ? {
        marginTop: 0,
      }
    : {
        position: "relative",
        marginBottom: !isOnline ? "-64px" : "0px",
        zIndex: 1,
      }),
}));

const WorkspaceWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transition: "padding-right 300ms ease-out,",
  paddingRight: expanded ? theme.spacing(7) : 0,
  height: theme.spacing(7),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));

export const NavHeader = (props: NavHeaderProps) => {
  const { workspace, user } = props;
  const isOnline = useIsOnline();
  const { expanded } = useNavigationStore();

  return (
    <SidebarHeaderContainer expanded={expanded} isOnline={isOnline}>
      <ExpandCollapseButton />
      <WorkspaceWrapper expanded={expanded}>
        {workspace?.myRole && (
          <WorkspaceSelectionContainer expanded={expanded} isOnline={isOnline}>
            <WorkspaceSelectionButton workspace={workspace} user={user} />
          </WorkspaceSelectionContainer>
        )}
        {!isOnline && <ConnectionIndicator expanded={expanded} />}
      </WorkspaceWrapper>
    </SidebarHeaderContainer>
  );
};
