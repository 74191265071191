import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material";
import { alpha, lighten } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";

import { Tooltip, TooltipProps } from "..";

export interface IconButtonProps extends MuiIconButtonProps {
  "aria-label": string;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  size?: "small" | "medium" | "large";
  tooltip?: boolean;
  tooltipProps?: Partial<TooltipProps>;
  rounded?: boolean;
}

const Palette = {
  PRIMARY: {
    BACKGROUND: alpha("#52526F", 0.5),
    BORDER: alpha("#52526F", 0.25),
  },
  SECONDARY: {
    BACKGROUND: "#232434",
    BORDER: "#2C2D3C",
  },
  TERTIARY: {
    BACKGROUND: alpha("#191A23", 0.75),
    BORDER: "#2C2D3C",
  },
  QUATERNARY: {
    BACKGROUND: "transparent",
    BORDER: "transparent",
  },
};

const extraProps: PropertyKey[] = ["rounded", "variant"];

const StyledButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})((props: IconButtonProps) => {
  const { variant, rounded } = props;

  const variantStyles = {
    primary: {
      borderColor: Palette.PRIMARY.BORDER,
      backgroundColor: Palette.PRIMARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.PRIMARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.PRIMARY.BACKGROUND, 0.2),
      },
    },
    secondary: {
      borderColor: Palette.SECONDARY.BORDER,
      backgroundColor: Palette.SECONDARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.SECONDARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.SECONDARY.BACKGROUND, 0.2),
      },
    },
    tertiary: {
      borderColor: Palette.TERTIARY.BORDER,
      backgroundColor: Palette.TERTIARY.BACKGROUND,

      "&:hover": {
        borderColor: lighten(Palette.TERTIARY.BORDER, 0.2),
        backgroundColor: lighten(Palette.TERTIARY.BACKGROUND, 0.2),
      },
    },
    quaternary: {
      borderColor: Palette.QUATERNARY.BORDER,
      backgroundColor: Palette.QUATERNARY.BORDER,
    },
  };

  const styles = variantStyles[variant];

  if (rounded) {
    Object.assign(styles, {
      borderRadius: "50%",
    });
  }

  return styles;
});

const TooltipWrapper = styled("span")({
  lineHeight: 1,
});

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      size = "medium",
      variant = "primary",
      rounded = false,
      tooltip = true,
      tooltipProps,
      ...rest
    }: IconButtonProps,
    ref,
  ) => {
    if (tooltip) {
      return (
        <Tooltip title={rest["aria-label"]} {...tooltipProps} enterDelay={300}>
          {/* span is needed because disabled components can't be children of tooltips */}
          <TooltipWrapper>
            <StyledButton
              {...rest}
              size={size}
              variant={variant}
              rounded={rounded}
              ref={ref}
            />
          </TooltipWrapper>
        </Tooltip>
      );
    }

    return (
      <StyledButton
        {...rest}
        size={size}
        variant={variant}
        rounded={rounded}
        ref={ref}
      />
    );
  },
);
