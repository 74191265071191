import { useMutation } from "urql";

import {
  CreateStyleInput,
  DeleteStyleInput,
  UpdateStyleInput,
} from "../../components/graphql/schema.graphql";
import {
  CREATE_FORM_FIELDS,
  InferenceFormNavigationState,
} from "../../components/inference-form-v2/constants";
import { useNavigate } from "../";
import { useUrqlResponseHandler } from "../useUrqlResponseHandler";
import { CreateStyle2, DeleteStyle2, UpdateStyle2 } from "./queries.graphql";

export const useStyleActions = () => {
  const navigate = useNavigate();
  const [createMutation, executeCreation] = useMutation(CreateStyle2);
  const [updateMutation, executeUpdate] = useMutation(UpdateStyle2);
  const [deleteMutation, executeDelete] = useMutation(DeleteStyle2);

  const { getResponseFromState } = useUrqlResponseHandler();

  const createStyle = async (
    input: CreateStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeCreation({
      createStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style created",
      errorMessage: "Unfortunately we could not create your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const updateStyle = async (
    input: UpdateStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeUpdate({
      updateStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style updated",
      errorMessage: "Unfortunately we could not update your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const deleteStyle = async (
    input: DeleteStyleInput,
    successMessage?: string,
  ) => {
    const state = await executeDelete({
      deleteStyleInput: input,
    });
    const { data, error } = getResponseFromState({
      state,
      successMessage: successMessage || "Style deleted",
      errorMessage: "Unfortunately we could not delete your style",
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const navigateToForgeWithStyle = (workspaceName: string, styleId: string) => {
    const style = { id: styleId, weight: 100 };
    navigate<InferenceFormNavigationState>(`/${workspaceName}/session/new`, {
      state: {
        form: {
          [CREATE_FORM_FIELDS.STYLE]: style,
        },
        mode: "CREATE" as const,
      },
    });
  };

  return {
    createStyle: {
      execute: createStyle,
      state: createMutation,
    },
    updateStyle: {
      execute: updateStyle,
      state: updateMutation,
    },
    deleteStyle: {
      execute: deleteStyle,
      state: deleteMutation,
    },
    navigateToForgeWithStyle,
  };
};
