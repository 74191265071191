import { ResolverConfig } from "@urql/exchange-graphcache";

import {
  QueryGetFileByIdArgs,
  QueryGetSessionByIdArgs,
  QueryGetStyleByIdArgs,
} from "./schema.graphql";

const resolverConfig: Partial<ResolverConfig> = {
  Query: {
    getSessionById: (_result, args: QueryGetSessionByIdArgs, _cache, _info) => {
      return { __typename: "Session", id: args.input.sessionId };
    },
    getStyleById: (_result, args: QueryGetStyleByIdArgs, _cache, _info) => {
      return { __typename: "Style", id: args.input.styleId };
    },
    getFileById: (_result, args: QueryGetFileByIdArgs, _cache, _info) => {
      return { __typename: "File", id: args.input.fileId };
    },
  },
};

export default resolverConfig;
