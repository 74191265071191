import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Icon, Tooltip } from "../../..";
import { IndicatorContainer } from "../IndicatorContainer/IndicatorContainer";

export const ActiveIndicator = () => (
  <Tooltip title="This style is currently selected.">
    <IndicatorContainer>
      <Icon size="medium" icon={regular("check")} />
    </IndicatorContainer>
  </Tooltip>
);
