import * as yup from "yup";

import {
  CREATE_FORM_FIELDS,
  CreateFormType,
  MAX_DENOISING_STEPS,
  MAX_PROMPT_STRENGTH,
  MAX_SEED,
  MAX_STYLE_WEIGHT,
  MIN_DENOISING_STEPS,
  MIN_PROMPT_STRENGTH,
  MIN_SEED,
  MIN_STYLE_WEIGHT,
  PERFORMANCE_OPTIONS,
  ReferenceType,
} from "./constants";

export const createValidationSchema = yup.object().shape({
  [CREATE_FORM_FIELDS.PROMPT_TEXT]: yup
    .string()
    .when(CREATE_FORM_FIELDS.REFERENCES, {
      is: (references: ReferenceType[]) =>
        !references.some((reference) => reference.image),
      then: (schema) => schema.required("Prompt text is required."),
    }),
  [CREATE_FORM_FIELDS.NEGATIVE_PROMPT_TEXT]: yup.string(),
  [CREATE_FORM_FIELDS.STYLE]: yup.object().shape({
    id: yup.string(),
    weight: yup
      .number()
      .min(MIN_STYLE_WEIGHT, `Weight must be at least ${MIN_STYLE_WEIGHT}.`)
      .max(MAX_STYLE_WEIGHT, `Weight must be at most ${MAX_STYLE_WEIGHT}.`),
  }),
  [CREATE_FORM_FIELDS.PERFORMANCE]: yup
    .string()
    .oneOf(PERFORMANCE_OPTIONS)
    .required("Performance type is required."),
  [CREATE_FORM_FIELDS.SEED]: yup
    .number()
    .nullable()
    .test(
      "is-valid-seed",
      `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
      (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
    ),
  [CREATE_FORM_FIELDS.PROMPT_STRENGTH]: yup
    .number()
    .required("Prompt strength is required")
    .min(
      MIN_PROMPT_STRENGTH,
      `Prompt strength must be at least ${MIN_PROMPT_STRENGTH}.`,
    )
    .max(
      MAX_PROMPT_STRENGTH,
      `Prompt strength must be at most ${MAX_PROMPT_STRENGTH}.`,
    ),
  [CREATE_FORM_FIELDS.DENOISING_STEPS]: yup
    .number()
    .required("Denoising steps is required")
    .min(
      MIN_DENOISING_STEPS,
      `Denoising steps must be at least ${MIN_DENOISING_STEPS}.`,
    )
    .max(
      MAX_DENOISING_STEPS,
      `Denoising steps must be at most ${MAX_DENOISING_STEPS}.`,
    ),
  [CREATE_FORM_FIELDS.TRANSPARENCY]: yup
    .boolean()
    .required("Transparency is required"),
  [CREATE_FORM_FIELDS.DIMENSIONS]: yup
    .array()
    .of(yup.number())
    .length(2)
    .required("Dimensions are required"),
}) as yup.ObjectSchema<CreateFormType>;
